import React, { useEffect, useId, useState } from 'react';
import styles from '@styles/AirBookingPage.module.css';
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    TableFooter,
    Dimmer,
    Loader,
    List,
    ListHeader,
    ListContent,
    ListDescription,
    ListItem,
    AccordionTitle,
    AccordionContent,
    Accordion,
    Icon,
} from 'semantic-ui-react';
import PassengersForm, { AgeType } from '../../components/air/PassengersForm';
import SegmentsTable from '../../components/air/SegmentsTable';
import BackBar from '@components/BackBar';
import { useLocation, useNavigate } from 'react-router';
import { GetUserInfo } from '@utils/storage';
import PropTypes from 'prop-types';
import useFlightCreateOrder from '../../hooks/flight/useFlightCreateOrder';
import { App, Result, Button } from 'antd';
import { ScrollToTop } from '@utils/scrollPosition';

function AirBookingPage(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const loginUser = GetUserInfo();
    const { routing, sessionId, maxSeats = 1, ticketInfo } = location.state || {};
    if (!routing || routing.data == '') {
        return (
            <div className={styles.container}>
                <BackBar />
                <br />
                <div> No routing!!!</div>
            </div>
        );
    }
    const [passengers, setPassengers] = useState([]);
    const formID = useId();
    const { submitting, handleCreateOrder, abort } = useFlightCreateOrder();
    const { message } = App.useApp();
    const [success, SetSuccess] = useState(false);

    useEffect(() => {
        // console.log(routing);
        // scroll to top
        ScrollToTop();
        return () => {
            abort();
        };
    }, []);

    const total = () => {
        let total = 0;
        for (let p in passengers) {
            if (p.ageType == AgeType.Child) {
                total += routing.childTotal;
            } else {
                total += routing.adultTotal;
            }
        }
        return total;
    };

    const Rule = ({ rule = {} }) => {
        const [activeIndex, setActiveIndex] = useState(-1);

        const handleClick = (e, titleProps) => {
            const { index } = titleProps;
            const newIndex = activeIndex === index ? -1 : index;
            setActiveIndex(newIndex);
        };
        return (
            <>
                <Accordion>
                    <AccordionTitle active={activeIndex === 0} index={0} onClick={handleClick}>
                        <h3 style={{ display: 'inline' }}>退改规则</h3>
                        <Icon name="dropdown" />
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === 0}>
                        <List>
                            <ListItem>
                                <ListContent>
                                    <ListHeader>改签</ListHeader>
                                    <ListDescription>
                                        <pre>{rule.change}</pre>
                                    </ListDescription>
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListContent>
                                    <ListHeader>退票</ListHeader>
                                    <ListDescription>
                                        <pre>{rule.refund}</pre>
                                    </ListDescription>
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListContent>
                                    <ListHeader>出票</ListHeader>
                                    <ListDescription>
                                        <pre>{rule.tktgInfo}</pre>
                                    </ListDescription>
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListContent>
                                    <ListHeader>其他</ListHeader>
                                    <ListDescription>{rule.other}</ListDescription>
                                </ListContent>
                            </ListItem>
                        </List>
                    </AccordionContent>
                    <AccordionTitle active={activeIndex === 1} index={1} onClick={handleClick}>
                        <h3 style={{ display: 'inline' }}>行李配额</h3>
                        <Icon name="dropdown" />
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === 1}>
                        <List>
                            <ListItem>
                                <ListContent>
                                    <ListDescription>
                                        <pre>{rule.baggage}</pre>
                                    </ListDescription>
                                </ListContent>
                            </ListItem>
                        </List>
                    </AccordionContent>
                </Accordion>
            </>
        );
    };

    Rule.propTypes = {
        rule: PropTypes.object.isRequired,
    };

    const handleSubmit = () => {
        // Validate form
        const passengerForm = document.getElementById(formID);
        if (passengerForm.reportValidity() != true) return;
        // Confirm
        if (confirm('确定提交') != true) return;
        const req = {
            channelSource: routing.channelSource,
            sessionId: sessionId,
            passengers: passengers,
            routing: routing,
            contact: {
                name: loginUser.contactName,
                email: loginUser.email,
                mobile: loginUser.contactNumber,
            },
        };
        console.log(req);
        handleCreateOrder(req)
            .then((data) => {
                const reply = data.reply;
                message?.success(reply);
                SetSuccess(true);
            })
            .catch((err) => {
                console.error(err);
                message?.error(err.message);
            });
    };

    const gotoOrders = () => {
        navigate({ pathname: '/dashboard/air-tickets/fit/orders' }, { replace: true });
    };

    const goBack = () => {
        navigate({ pathname: '/dashboard/air-tickets/fit' }, { replace: true });
    };

    const Success = () => {
        return (
            <Result
                status="success"
                title={`Successfully!`}
                extra={[
                    <Button type="primary" key="orders" size="small" onClick={gotoOrders}>
                        Goto orders
                    </Button>,
                    <Button key="back" size="small" onClick={goBack}>
                        返回
                    </Button>,
                ]}
            />
        );
    };

    if (success) {
        return <Success />;
    }

    return (
        <div className={styles.container}>
            <BackBar />
            <h3>航班信息</h3>
            <SegmentsTable segments={[...routing.fromSegments, ...routing.retSegments]} />

            {/* 退改规则 */}
            <Rule rule={routing.rule} />

            <h3>乘客信息(可预订的座位数 {maxSeats})</h3>
            <PassengersForm passengers={passengers} setPassengers={setPassengers} formID={formID} maxSeats={maxSeats} />

            <h3>价格</h3>
            <Table celled structured>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>乘客类型</TableHeaderCell>
                        <TableHeaderCell>票面</TableHeaderCell>
                        <TableHeaderCell>开票费</TableHeaderCell>
                        <TableHeaderCell>税金</TableHeaderCell>
                        <TableHeaderCell>单张结算价格</TableHeaderCell>
                        <TableHeaderCell>出票时间</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <TableRow>
                        <TableCell>成人</TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {routing.adultPrice}
                        </TableCell>
                        <TableCell>{routing.currency ?? 'CNY'} 0</TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {routing.adultTax}
                        </TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {routing.adultTotal}
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>儿童</TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {routing.childPrice}
                        </TableCell>
                        <TableCell>{routing.currency ?? 'CNY'} 0</TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {routing.childTax}
                        </TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {routing.childTotal}
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableHeaderCell textAlign="right" colSpan="6">
                            <span style={{ fontSize: '18px', color: 'tomato' }}>总价:{total()}</span>
                        </TableHeaderCell>
                    </TableRow>
                </TableFooter>
            </Table>
            {/* 出票须知 */}
            <p>*{ticketInfo?.remark}</p>
            <div className={styles.action_container}>
                <Button type="primary" onClick={handleSubmit} form={formID}>
                    创建订单
                </Button>
            </div>
            <Dimmer active={submitting} page inverted>
                <Loader>处理中...</Loader>
            </Dimmer>
        </div>
    );
}

export default AirBookingPage;
