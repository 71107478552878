import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table } from 'semantic-ui-react';
import { format, parse } from 'date-fns';

function SegmentsTable({ segments = [] }) {
    const timeParse = (str) => {
        const parsedDate = parse(str, 'yyyyMMddHHmm', new Date());
        return format(parsedDate, 'yyyy/MM/dd HH:mm');
    };

    return (
        <Table celled structured>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>序号</TableHeaderCell>
                    <TableHeaderCell>航司</TableHeaderCell>
                    <TableHeaderCell>航班号</TableHeaderCell>
                    <TableHeaderCell>舱位</TableHeaderCell>
                    <TableHeaderCell>航程</TableHeaderCell>
                    <TableHeaderCell>出行时间</TableHeaderCell>
                    <TableHeaderCell>行李额</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {segments.map((item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.carrier}</TableCell>
                            <TableCell>
                                {item.flightNumber}
                                {item.codeShare && `(${item.sharingFlightNumber})`}
                            </TableCell>
                            <TableCell>{item.cabin}</TableCell>
                            <TableCell>
                                {item.depAirport} - {item.arrAirport}
                            </TableCell>
                            <TableCell>
                                {timeParse(item.depTime)} - {timeParse(item.arrTime)}
                            </TableCell>
                            <TableCell>
                                {!!item.baggagePieces && (
                                    <>
                                        {item.baggagePieces}PC
                                        {item.baggageWeight && `/${item.baggageWeight}Kg`}
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

SegmentsTable.propTypes = {
    segments: PropTypes.array.isRequired,
};

export default SegmentsTable;
