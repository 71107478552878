import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, FormSelect, Icon } from 'semantic-ui-react';
import { countryOptions } from '@utils/ios3166_1';
import { DevMode } from '../../utils/utils';

//`Muhammad/XKAI` is test passenger
const genTestData = () => {
    // generate random date or birth for age 15-50, in format yyyy-mm-dd
    const genRandomDob = () => {
        const year = new Date().getFullYear() - Math.floor(Math.random() * 35) - 15;
        const month = Math.floor(Math.random() * 12) + 1;
        const day = Math.floor(Math.random() * 28) + 1;
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    };
    // generate random expire date for passport, in format yyyy-mm-dd, 2-5 years from now
    const genRandomExpire = () => {
        const year = Math.floor(Math.random() * 3) + 2 + new Date().getFullYear();
        const month = Math.floor(Math.random() * 12) + 1;
        const day = Math.floor(Math.random() * 28) + 1;
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    };
    // generate random passport number, start with E or R, followed by 8 digits
    const genRandomPassport = () => {
        const prefix = Math.random() > 0.5 ? 'E' : 'R';
        const digits = Math.floor(Math.random() * 100000000);
        return `${prefix}${digits.toString().padStart(8, '0')}`;
    };

    // generate random nationality, CN or US or AU
    const genRandomNationality = () => {
        const nats = ['CN', 'US', 'AU'];
        return nats[Math.floor(Math.random() * nats.length)];
    };

    // generate random first name from a list
    const firstnames = ['XKAI', 'Mingyu', 'Xiaoming', 'Xiaohong', 'Xiaoli', 'Xiaofang', 'Xiaoxiao', 'Xiaohua'];
    // a list of aribic last names
    const arabicLastNames = [
        'Farsi',
        'Haddad',
        'Hussein',
        'Masri',
        'Sharif',
        'Qahtani',
        'Najjar',
        'Sayed',
        'Mahdi',
        'Rashid',
        'Fahad',
        'Khalifa',
        'Salem',
        'Muammar',
        'Zayani',
        'Hashimi',
        'Khatib',
        'Bukhari',
        'Harbi',
        'Jabari',
        'Muhammad',
    ];
    const getFirstname = () => {
        return firstnames[Math.floor(Math.random() * firstnames.length)];
    };
    const getLastname = () => {
        return arabicLastNames[Math.floor(Math.random() * arabicLastNames.length)];
    };

    return {
        ageType: 0,
        firstname: getFirstname(),
        lastname: getLastname(),
        gender: 'M',
        dob: genRandomDob(),
        nationalCode: genRandomNationality(),
        idType: 'pp',
        idNumber: genRandomPassport(),
        idExpire: genRandomExpire(),
        mobile: '13934606977',
    };
};

export const DefaultData = {
    ageType: '',
    firstname: '',
    lastname: '',
    gender: '',
    dob: '',
    nationalCode: '',
    idType: '',
    idNumber: '',
    idExpire: '',
    mobile: '',
};

export const AgeType = {
    Adult: 0,
    Child: 1,
};

function PassengersForm({ passengers = [], setPassengers = () => {}, formID, maxSeats = 1 }) {
    useEffect(() => {
        if (passengers.length == 0) {
            setPassengers((prev) => {
                return [...prev, { ...DefaultData }];
            });
        }
        return () => {};
    }, [passengers]);

    const handleAdd = () => {
        // MaxSeat
        if (passengers.length >= maxSeats) return;
        setPassengers((prev) => {
            return [...prev, { ...DefaultData }];
        });
    };

    const handleRemove = (index) => {
        console.log(index);
        const newData = [...passengers];
        newData.splice(index, 1);
        setPassengers([...newData]);
    };

    const handleOnChange = (e, { name, value }, index) => {
        console.log({ [name]: value });
        const newData = [...passengers];
        newData[index][name] = value;
        setPassengers(newData);
    };

    const handleAddTestPAX = (index) => {
        console.log('Adding test pax');

        setPassengers((prev) => {
            const testPax = genTestData();
            const newData = [...prev];
            newData[index] = testPax;
            return newData;
        });
    };

    return (
        <div>
            <Form id={formID}>
                {passengers.map((item, index) => {
                    return (
                        <div key={index}>
                            <div style={{ marginBottom: '12px' }}>
                                <h4>
                                    乘客 {index + 1}{' '}
                                    {DevMode() && <Icon name="spy" onClick={() => handleAddTestPAX(index)} />}{' '}
                                </h4>
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '100%' }}>
                                    <FormGroup widths="equal">
                                        <FormSelect
                                            width={6}
                                            fluid
                                            label="乘客类型"
                                            name="ageType"
                                            required
                                            options={[
                                                { key: 1, text: '成人', value: AgeType.Adult },
                                                { key: 2, text: '儿童', value: AgeType.Child },
                                            ]}
                                            value={item.ageType}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            placeholder="类型"
                                        />
                                        <Form.Input
                                            name="lastname"
                                            label="姓氏(Last Name)"
                                            placeholder="e.g Li"
                                            autoCapitalize="words"
                                            value={item.lastname}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            required
                                            type="text"
                                        />
                                        <Form.Input
                                            name="firstname"
                                            label="名字(First Name)"
                                            placeholder="e.g Mingyu"
                                            autoCapitalize="words"
                                            value={item.firstname}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            required
                                            type="text"
                                        />
                                        <FormSelect
                                            name="gender"
                                            width={5}
                                            fluid
                                            label="性别"
                                            value={item.gender}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            required
                                            options={[
                                                { key: 'm', text: '男', value: 'M' },
                                                { key: 'f', text: '女', value: '' },
                                            ]}
                                        />
                                        <Form.Input
                                            name="dob"
                                            width={10}
                                            fluid
                                            value={item.dob}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            label="出生日期"
                                            required
                                            type="date"
                                        />
                                    </FormGroup>
                                    <FormGroup widths="equal">
                                        <FormSelect
                                            name="nationalCode"
                                            width={8}
                                            fluid
                                            label="国籍"
                                            required
                                            value={item.nationalCode}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            options={countryOptions}
                                            selection
                                            search={(options, data) => {
                                                return options.filter((item) => {
                                                    return (
                                                        item?.text?.includes(data) ||
                                                        item?.texten?.toLowerCase().startsWith(data) ||
                                                        item?.value?.toLowerCase().startsWith(data)
                                                    );
                                                });
                                            }}
                                            clearable
                                            autoComplete="off"
                                        />
                                        <FormSelect
                                            name="idType"
                                            width={6}
                                            fluid
                                            label="证件类型"
                                            required
                                            value={item.idType}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            options={[{ key: 1, text: '护照', value: 'pp' }]}
                                            placeholder="类型"
                                        />
                                        <Form.Input
                                            name="idNumber"
                                            label="证件号码"
                                            placeholder="证件号码"
                                            value={item.idNumber}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            required
                                            type="text"
                                        />
                                        <Form.Input
                                            width={10}
                                            fluid
                                            name="idExpire"
                                            label="有效日期"
                                            value={item.idExpire}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            required
                                            type="date"
                                        />
                                        <Form.Input
                                            name="mobile"
                                            value={item.mobile}
                                            onChange={(e, data) => handleOnChange(e, data, index)}
                                            label="电话号码"
                                            placeholder="电话号码"
                                            type="tel"
                                            required
                                        />
                                    </FormGroup>
                                </div>
                                <div style={{ marginLeft: '8px' }}>
                                    <Button circular icon onClick={() => handleRemove(index)} type="button" size="tiny">
                                        <Icon name="trash alternate" color="red" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Form>
            <Button circular icon onClick={handleAdd}>
                <Icon name="add" />
            </Button>
        </div>
    );
}

PassengersForm.propTypes = {
    passengers: PropTypes.array.isRequired,
    setPassengers: PropTypes.func.isRequired,
    formID: PropTypes.string,
    maxSeats: PropTypes.number,
};

export default PassengersForm;
