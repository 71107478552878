import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import useGroupEnquiry from '../../hooks/flight/useGroupEnquiry';
import BackBar from '../../components/BackBar';
import { Spin, Tag, Descriptions, Space, Input, Button, App, Modal } from 'antd';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
import useGroupEnquiryUpdate from '../../hooks/flight/useGroupEnquiryUpdate';
import Comments from '../../components/Comments';
import TextareaAutosize from 'react-textarea-autosize';

function GroupEnquiryPage() {
    const { id } = useParams();
    const { fetching, data, fetchData, abort: abortFetch } = useGroupEnquiry();
    const { submitting, handleEnquiryUpdate, abort: abortUpdate } = useGroupEnquiryUpdate();
    const [openGroupName, setOpenGroupName] = useState(false);
    const [openRemark, setOpenRemark] = useState(false);

    const inputRef = useRef(null);
    const remarkRef = useRef(null);
    const { message } = App.useApp();

    if (!id) {
        return <div>No Enquiry ID</div>;
    }

    useEffect(() => {
        fetchData(id);
        return () => {
            abortFetch();
            abortUpdate();
        };
    }, [id]);

    const getItems = (data) => {
        if (!data) {
            return [];
        }
        return [
            {
                key: '1',
                label: '团队名称',
                children: (
                    <div>
                        {data.groupName}{' '}
                        <Button
                            type="link"
                            size="small"
                            onClick={() => setOpenGroupName((prev) => !prev)}
                            icon={<EditOutlined />}
                        ></Button>
                        <Modal
                            open={openGroupName}
                            onCancel={() => setOpenGroupName(false)}
                            okText="Save"
                            cancelText="Cancel"
                            onOk={handleOnSave}
                            centered
                        >
                            <h3>Edit Group Name</h3>
                            <Input ref={inputRef} defaultValue={data.groupName} />
                        </Modal>
                    </div>
                ),
            },
            {
                key: '2',
                label: '提交时间',
                children: dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            },
            {
                key: '3',
                label: '成人票',
                children: data.numAdult,
            },
            {
                key: '4',
                label: '儿童票',
                children: data.numChild,
            },
            {
                key: '6',
                label: '航班',
                span: 2,
                children: (
                    <Space direction="vertical">
                        {data.flightSegments.map((item, index) => {
                            return (
                                <div key={index}>
                                    <Tag color="blue">{item.date}</Tag>
                                    <Tag color="blue">{item.fromAirport}</Tag>
                                    <Tag color="blue">{item.toAirport}</Tag>
                                    <Tag color="green">{item.carrierCode}</Tag>
                                    <Tag color="green">{item.cabinClass}</Tag>
                                    <Tag color="green">{item.flightNumbers.join(', ')}</Tag>
                                </div>
                            );
                        })}
                    </Space>
                ),
            },
            {
                key: '7',
                label: '备注',
                span: 2,
                children: (
                    <div>
                        {data.remark}{' '}
                        <Button
                            type="link"
                            size="small"
                            onClick={() => setOpenRemark((prev) => !prev)}
                            icon={<EditOutlined />}
                        ></Button>
                        <Modal
                            open={openRemark}
                            onCancel={() => setOpenRemark(false)}
                            onOk={handleOnSave}
                            okText="Save"
                            centered
                        >
                            <h3>Edit Remark</h3>
                            <TextareaAutosize
                                style={{ width: '100%', padding: '8px' }}
                                ref={remarkRef}
                                minRows={6}
                                placeholder="备注"
                                defaultValue={data.remark}
                            />
                        </Modal>
                    </div>
                ),
            },
        ];
    };

    const handleOnSave = () => {
        const req = {};
        if (inputRef?.current?.input?.value) {
            req.groupName = inputRef.current.input.value;
        }
        if (remarkRef?.current?.value) {
            req.remark = remarkRef?.current.value;
        }
        // console.log(req);
        handleEnquiryUpdate(id, req)
            .then((res) => {
                setOpenGroupName(false);
                setOpenRemark(false);
                if (res.reply) {
                    message.success(res.reply);
                }
                fetchData(id);
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    return (
        <div>
            <BackBar />
            <h3>
                团队询价 <Spin spinning={fetching} />
            </h3>
            <div>
                <Descriptions items={getItems(data.enquiry)} bordered column={2} />
                <br />
                <Comments commentOnID={id} />
            </div>
        </div>
    );
}

export default GroupEnquiryPage;
